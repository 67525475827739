import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {useTheme} from '@unthinkable/react-theme';
import React from 'react';
import {IconButton} from '../../../components/button/IconButton';
import {
  GroupFilter,
  PeriodRangeMonthWiseBreakupFilter,
} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {UserAvatarWithNameAndEmployeeCode} from '../../reimbursement/components/ReimbursementColumnRenders';
import {Button} from '../../../components/button/Button';
import {useInvoke} from '../../../controllers/useInvoke';
import CountCellRender from '../../../app-components/renders/CountCellRender';

export const OfficeTimeTrackingReport = props => {
  let {navigation} = props;
  const {filterValues, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const invoke = useInvoke({
    method: 'post',
  });
  const {params: filterParams, filter} = filterValues;
  const {period} = filterParams;
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  return (
    <Table
      {...props}
      api={'/employeeofficetimetraking'}
      params={{...filterParams, ...filter}}
      variant={'bordered'}
      limit={2000}
      renderHeader={() => (
        <TableHeader
          title="Office Time Tracking"
          actions={[
            <PeriodRangeMonthWiseBreakupFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'multiAutoComplete',
                  label: 'Employee',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  titleField: 'name',
                  field: 'employee',
                  searchFields: ['name', 'official_email_id', 'employee_code'],
                },
                {
                  type: 'multiAutoComplete',
                  label: 'Reporting To',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  titleField: 'name',
                  field: 'reporting_to',
                  searchFields: ['name', 'official_email_id', 'employee_code'],
                },
                {
                  type: 'multiAutoComplete',
                  label: 'Location',
                  api: '/offices',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'sitting_location',
                },
                {
                  type: 'autoComplete',
                  label: 'Exceptional Shift',
                  options: [
                    {label: 'True', value: 'true'},
                    {label: 'False', value: {$in: [null, false]}},
                  ],
                  placeholder: 'Select',
                  field: 'is_exceptional_shift',
                  suggestionField: 'label',
                  keyField: 'value',
                  valueField: 'label',
                },
              ]}
            />,
            <Button
              text="Send Mail"
              onPress={() => {
                invoke({
                  uri: `/sendTimeTrackingMailToCeo`,
                  props: {
                    ...filterParams,
                    ...filter,
                  },
                });
              }}
            />,
            <IconButton
              icon={Download}
              onPress={() => {
                download({
                  uri: '/downloadOfficeTimeTrackingReport',
                  props: {
                    ...filterParams,
                    ...filter,
                  },
                });
              }}
            />,
          ]}
        />
      )}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
              />
            );
          },
          width: 300,
        },
        {
          header: 'Department',
          align: 'right',
          type: 'text',
          field: 'department.name',
          width: 250,
        },
        {
          header: 'Total',
          type: 'number',
          align: 'right',
          field: 'totalDays',
          width: 130,
        },
        {
          header: 'In Office',
          type: 'number',
          align: 'right',
          field: 'inOfficeCount',
          render: CountCellRender,
          count_field: 'inOfficeCount',
          onPress: ({row}) => {
            navigation.navigate('employee-attendance', {
              filter: {
                _id: {$in: row.inOfficeCountIds || []},
              },
              fromReport: true,
            });
          },
          width: 130,
        },
        {
          header: 'Late Days',
          type: 'number',
          align: 'right',
          field: 'lateInOffice',
          render: CountCellRender,
          count_field: 'lateInOffice',
          onPress: ({row}) => {
            navigation.navigate('employee-attendance', {
              filter: {
                _id: {$in: row.lateInOfficeIds || []},
              },
              fromReport: true,
            });
          },
          width: 130,
        },
        {
          header: 'Hours <9',
          type: 'number',
          align: 'right',
          field: 'lessThan9hrs',
          render: CountCellRender,
          count_field: 'lessThan9hrs',
          onPress: ({row}) => {
            navigation.navigate('employee-attendance', {
              filter: {
                _id: {$in: row.lessThan9hrsIds || []},
              },
              fromReport: true,
            });
          },
          width: 130,
        },
        {
          header: 'Avg Time',
          type: 'text',
          align: 'right',
          field: 'avgTime',
          width: 230,
        },
      ]}
    />
  );
};

export const TeamOfficeTimeTrackingReport = props => {
  let {
    navigation,
    filter,
    route: {params},
    tab,
    api,
  } = props;
  let trackingFilter = {};
  if (params.filter) {
    trackingFilter = {
      ...params.filter,
    };
  }

  return (
    <Table
      {...props}
      api={api ? api : '/employeeofficetimetraking'}
      params={{...filter, ...trackingFilter}}
      variant={'bordered'}
      limit={2000}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Department',
          align: 'right',
          type: 'text',
          field: 'department.name',
          width: 200,
        },
        {
          header: 'Team',
          align: 'center',
          render: CountCellRender,
          count_field: 'teamCount',
          onPress: ({row}) => {
            navigation.navigate('office-time-tracking-report', {
              filter: {...filter, reporting_to: row._id},
              tab: 'drill',
            });
          },
          visible: () => {
            if (params?.tab === 'drill' || tab === 'Indirect') {
              return false;
            } else return true;
          },
          type: 'number',
        },
        {
          header: 'Total',
          type: 'number',
          align: 'right',
          field: 'totalDays',
          width: 130,
        },
        {
          header: 'In Office',
          type: 'number',
          align: 'right',
          field: 'inOfficeCount',
          render: CountCellRender,
          count_field: 'inOfficeCount',
          onPress: ({row}) => {
            console.log('row>>>>>>>>>>', row);
            navigation.navigate('employee-attendance', {
              filter: {
                _id: {$in: row.inOfficeCountIds || []},
              },
              fromReport: true,
            });
          },
          width: 130,
        },
        {
          header: 'Late Days',
          type: 'number',
          align: 'right',
          field: 'lateInOffice',
          render: CountCellRender,
          count_field: 'lateInOffice',
          onPress: ({row}) => {
            navigation.navigate('employee-attendance', {
              filter: {
                _id: {$in: row.lateInOfficeIds || []},
              },
              fromReport: true,
            });
          },
          width: 130,
        },
        {
          header: 'Hours <9',
          type: 'number',
          align: 'right',
          field: 'lessThan9hrs',
          render: CountCellRender,
          count_field: 'lessThan9hrs',
          onPress: ({row}) => {
            navigation.navigate('employee-attendance', {
              filter: {
                _id: {$in: row.lessThan9hrsIds || []},
              },
              fromReport: true,
            });
          },
          width: 130,
        },
        {
          header: 'Avg Time',
          type: 'text',
          align: 'right',
          field: 'avgTime',
          width: 230,
        },
      ]}
    />
  );
};

export const SelfOfficeTimeTrackingReport = props => {
  let {navigation} = props;
  const {filterValues, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {user} = useAppStateContext();

  return (
    <Table
      {...props}
      api={'/employeeofficetimetraking'}
      params={{...filterValues?.params, employee: user?.employee?._id}}
      variant={'bordered'}
      limit={2000}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
              />
            );
          },
          width: 300,
        },
        {
          header: 'Department',
          align: 'right',
          type: 'text',
          field: 'department.name',
          width: 250,
        },
        {
          header: 'Total',
          type: 'number',
          align: 'right',
          field: 'totalDays',
          width: 130,
        },
        {
          header: 'In Office',
          type: 'number',
          align: 'right',
          field: 'inOfficeCount',
          render: CountCellRender,
          count_field: 'inOfficeCount',
          onPress: ({row}) => {
            navigation.navigate('employee-attendance', {
              filter: {
                _id: {$in: row.inOfficeCountIds || []},
              },
              fromReport: true,
            });
          },
          width: 130,
        },
        {
          header: 'Late Days',
          type: 'number',
          align: 'right',
          field: 'lateInOffice',
          render: CountCellRender,
          count_field: 'lateInOffice',
          onPress: ({row}) => {
            navigation.navigate('employee-attendance', {
              filter: {
                _id: {$in: row.lateInOfficeIds || []},
              },
              fromReport: true,
            });
          },
          width: 130,
        },
        {
          header: 'Hours <9',
          type: 'number',
          align: 'right',
          field: 'lessThan9hrs',
          render: CountCellRender,
          count_field: 'lessThan9hrs',
          onPress: ({row}) => {
            navigation.navigate('employee-attendance', {
              filter: {
                _id: {$in: row.lessThan9hrsIds || []},
              },
              fromReport: true,
            });
          },
          width: 130,
        },
        {
          header: 'Avg Time',
          type: 'text',
          align: 'right',
          field: 'avgTime',
          width: 230,
        },
      ]}
    />
  );
};
