import moment from 'moment';
import {Image, Row, Text} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';

import {InlineForm} from '../../../components/form/Form';
import {Table} from '../../../components/table/Table';
import {FeatureColumnCard} from '../../../components/card/FeatureColumnCard';
import {AttachmentCountRender} from '../../../components/table/Renders';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useInvoke} from '../../../controllers/useInvoke';
import {getTime} from '../utility';
import {SearchFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {useFilter} from '../../../controllers/useFilter';
import {Labels} from '../constants/Label';

const issueInlineForm = props => {
  const {
    route: {params},
  } = props;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Issue',
    uri: '/projectIssues',
    ...props,
  });

  const {project, feature_id, module, projectlibrary_id} = params || {};

  const defaultValues = {
    project_id: project?._id,
    module_id: module?._id,
  };

  if (feature_id) {
    defaultValues.objective_ids = [feature_id];
  }

  if (projectlibrary_id) {
    defaultValues.library_id = projectlibrary_id;
  }

  const priorityOptions = [
    {
      label: 'URGENT',
      value: 'URGENT',
      chip: 'ERROR',
    },
    {label: 'HIGH', value: 'HIGH', chip: 'ACCENT4'},
    {
      label: 'MEDIUM',
      value: 'MEDIUM',
      chip: 'ACCENT1',
    },
    {label: 'LOW', value: 'LOW', chip: 'ACCENT3'},
  ];

  return (
    <InlineForm
      onSubmit={onSubmit}
      eventSourceId={'Issue'}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Issue',
          field: 'issue',
          type: 'textArea',
          required: true,
          inputProps: {
            maxRows: 2,
          },
        },
        {
          label: 'Priority',
          type: 'autoComplete',
          field: 'priority',
          width: 150,
          options: priorityOptions,
          colorField: 'chip',
          valueField: 'label',
          keyField: 'value',
          suggestionField: 'label',
          showChip: true,
        },
        {
          label: 'Type',
          type: 'autoComplete',
          field: 'type',
          options: ['Bug', 'Enhancement'],
          width: 150,
        },
        {
          field: 'file',
          inline: true,
          type: 'file',
          width: 170,
          multiple: true,
        },
      ]}
    />
  );
};

const deleteIssue = ({deleteInvoke}) => ({
  title: 'Delete',
  variant: 'error',
  confirm: {
    title: 'Delete Issue',
    message: 'Are you sure you want to delete issue?',
    confirmText: 'Delete',
  },
  onPress: ({row}) => {
    deleteInvoke({
      uri: `/projectIssues/${row?._id}`,
      props: {},
    });
  },
});

const mapFeature = ({navigation, params}) => ({
  title: `Map ${Labels.Feature}`,
  onPress: props => {
    const {row} = props;
    navigation.navigate(`map-${Labels.Feature_lower}`, {
      ...params,
      row,
      type: 'issue',
    });
  },
});

const mapMultipleFeature = ({navigation, params}) => {
  return {
    title: `Map ${Labels.Features}`,
    onPress: ({selectedIds, resetSelection}) => {
      navigation.navigate(`map-multiple-${Labels.Features_lower}`, {
        ...params,
        selectedIds,
        resetSelection,
        type: 'issue',
      });
    },
  };
};

const deleteMultipleIssues = ({deleteInvoke}) => {
  return {
    title: 'Delete',
    variant: 'error',
    confirm: {
      title: 'Delete',
      message: 'Are you sure you want to delete all selected items?',
      confirmText: 'Delete',
    },
    onPress: ({selectedIds, resetSelection}) => {
      deleteInvoke({
        uri: `/issues/batchRemove`,
        props: {
          selectedIds,
        },
      });
      resetSelection?.();
    },
  };
};

const getLatestFeature = ({features}) => {
  if (!features?.length) {
    return null;
  }

  const releasedFeatures = features.filter(
    feature => feature.status === 'released',
  );

  if (releasedFeatures?.length === features.length) {
    let latestReleasedFeature = releasedFeatures[0];
    let latestMoment = void 0;
    releasedFeatures.forEach(obj => {
      const currentMoment = moment(obj.releasedOn);
      if (currentMoment.isAfter(latestMoment)) {
        latestMoment = currentMoment;
        latestReleasedFeature = obj;
      }
    });
    return latestReleasedFeature;
  } else {
    const activeFeatures = features.filter(
      feature => feature.status !== 'released',
    );
    if (activeFeatures?.length) {
      return {
        feature: features.reduce((acc, obj) => {
          if (acc) {
            acc += '; ';
          }
          acc += obj.feature;
          return acc;
        }, ''),
      };
    }
  }
};

const FeatureRender = props => {
  const {row} = props;
  const {objective_ids: feature_ids} = row;
  const feature = getLatestFeature({features: feature_ids});
  const {colors, fonts, icons} = useTheme();

  if (!feature) {
    return null;
  }
  return (
    <Row gap={8} style={{justifyContent: 'flex-end'}}>
      <Image
        source={
          feature?.status === 'released' ? icons.StarGreen : icons.StarGray
        }
      />
      <Text
        numberOfLines={1}
        title={feature?.feature}
        style={{
          ...fonts['CAPTION_LARGE'],
          color:
            colors[
              feature?.status === 'released' ? 'SUCCESS_HIGH' : 'NEUTRAL_MEDIUM'
            ],
        }}>
        {feature?.feature}
      </Text>
    </Row>
  );
};

const issueColumn = {
  header: 'Issue',
  responsive: 'sm',
  render: ({row}) => {
    let {createdAt, issue, createdBy} = row;
    if (createdAt) {
      createdAt = `On ${getTime(createdAt)}`;
    }

    if (createdBy?.name) {
      createdBy = `By : ${createdBy?.name}`;
    }

    const {colors} = useTheme();

    return (
      <FeatureColumnCard
        primaryTitle={issue}
        secondaryTitleColor={colors.ACCENT6_HIGH}
        items={[
          {
            value: createdBy,
          },
          {
            value: createdAt,
          },
        ]}
        row={row}
        isIssue={true}
      />
    );
  },
};

const issueType = {
  header: 'Type',
  field: 'type',
  type: 'text',
  width: 120,
};

const feature = {
  header: {
    label: `${Labels.Feature}`,
    textAlign: 'right',
  },
  render: FeatureRender,
  width: 150,
};

const moduleColumn = {
  type: 'colorTag',
  field: 'module_id.module',
  colorField: 'module_id.color',
  header: 'Module',
  width: 150,
};

const issueAttachments = {
  render: AttachmentCountRender,
  type: 'file',
  field: 'file',
  header: 'Refer',
  showImage: true,
  count: true,
  width: 80,
  align: 'center',
};

const removeFromFeature = ({putInvoke, params}) => ({
  title: `Remove from ${Labels.Feature}`,
  variant: 'error',
  confirm: {
    title: 'Remove Issue',
    message: `Are you sure you want to remove this issue?`,
    confirmText: 'Delete',
  },
  onPress: ({row}) => {
    putInvoke({
      uri: `/issues/${row?._id}/removeObjective`,
      props: {
        objective_id: params?.feature?._id,
      },
    });
  },
  visible: params?.feature?._id,
});

const showFeatures = ({navigation, params, isPending}) => ({
  title: `View ${Labels.Feature}`,
  onPress: props => {
    const {row} = props;
    navigation.navigate(`display-${Labels.Features_lower}`, {
      ...params,
      row,
      secondaryTitle: `Issue : ${row?.issue}`,
      keyField: 'issue',
    });
  },
  visible: () => !isPending,
});

export const CommonIssueTable = props => {
  let {
    navigation,
    route: {params},
    searchValue,
    filter,
    isPending,
  } = props;

  const {module} = params;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'Issue',
  });

  const putInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'Issue',
  });

  return (
    <>
      {isPending && issueInlineForm(props)}
      <Table
        eventSourceId={['Issue', 'RemoveMappedFeature']}
        api={`/projectissues`}
        search={searchValue}
        searchFields={['issue']}
        sort={{index: -1}}
        filter={filter}
        fields={{
          issue: 1,
          type: 1,
          objective_ids: {feature: 1, createdAt: 1, status: 1},
          priority: 1,
          createdAt: 1,
          createdBy: {name: 1},
          file: 1,
          index: 1,
          module_id: {module: 1, color: 1},
        }}
        onRowPress={({row}) => {
          navigation.navigate('update-issue-form', {
            ...params,
            issue: row,
          });
        }}
        selection={{
          actions: [
            mapMultipleFeature({navigation, params}),
            deleteMultipleIssues({deleteInvoke}),
          ],
        }}
        draggable={{
          sort: 'desc',
          updateIndexOnDragEnd: ({updatedIndex, row}) => {
            putInvoke({
              uri: `/projectIssues/${row?._id}`,
              props: {
                index: updatedIndex,
              },
            });
          },
        }}
        columns={[
          issueColumn,
          issueType,
          !module?._id && moduleColumn,
          issueAttachments,
          !isPending && feature,
        ]}
        moreActions={[
          mapFeature({navigation, params}),
          showFeatures({
            navigation,
            params,
            isPending,
          }),
          deleteIssue({deleteInvoke}),
        ]}
      />
    </>
  );
};

export const IssuesTable = props => {
  let {
    navigation,
    route: {params},
  } = props;

  const {feature} = params;
  const {searchValue, onChangeFilter} = useFilter({});
  const putInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'Issue',
  });

  return (
    <Table
      renderHeader={() => (
        <>
          <TableHeader
            title="Issues"
            actions={[
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
              />,
            ]}
          />
          {feature.status !== 'released' && issueInlineForm(props)}
        </>
      )}
      searchFields={'issue'}
      search={searchValue}
      eventSourceId={['Issue']}
      api={`/objectives/${feature?._id}/issues`}
      onRowPress={({row}) => {
        navigation.navigate('update-issue-form', {
          ...params,
          issue: row,
        });
      }}
      columns={[issueColumn, issueType, issueAttachments]}
      moreActions={[removeFromFeature({putInvoke, params})]}
    />
  );
};
