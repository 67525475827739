import React from 'react';
import {Form} from '../../../../components/form/Form';
import {useFormSubmit} from '../../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../../providers/AppState';
import {repositoryFrameworks} from '../RepositoryForm';
import {LibraryTypes} from '../../constants/LibraryConstants';
import NestedTable from '../../../../components/form/NestedTable';

const LibraryForm = ({route: {params}, navigation, ...props}) => {
  const {project_id, libraryType} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/projectlibraries',
    eventSourceId: ['Library'],
    ...props,
  });

  const repoType =
    libraryType === LibraryTypes.DATA_SERVICE ? 'Backend' : 'Frontend';

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          required: true,
        },
        [
          {
            label: 'Repository',
            type: 'autoComplete',
            field: 'repository_id',
            api: '/projectRepositories',
            filter: {project_id, type: repoType},
            fields: {
              repository: 1,
              type: 1,
              language: 1,
              frameworks: 1,
              framework_version: 1,
            },
            suggestionField: 'repository',
            valueField: 'repository',
            size: 6,
            onChangeValue: (value, e, {setFieldValue}) => {
              if (value) {
                setFieldValue('language', value?.language);
                setFieldValue('framework', value?.frameworks[0]);
              } else {
                setFieldValue('folder_path', void 0);
                setFieldValue('language', void 0);
                setFieldValue('framework', void 0);
              }
            },
          },
          {
            label: 'Folder Path',
            field: 'folder_path',
            type: 'autoComplete',
            api: `/repositoryFolderPaths`,
            suggestionField: 'path',
            secondarySuggestionField: 'name',
            valueField: 'path',
            filter: {
              project_id,
            },
            onCreate: ({searchValue, onChange, form: {values}}) => {
              navigation.navigate(`add-repository-folder-path`, {
                ...params,
                repositoryType: values?.repository_id.type,
                defaultValues: {
                  project_id: project_id,
                  name: searchValue,
                  repository_id: values?.repository_id,
                },
                afterSubmit: ({data}) => onChange?.(data),
              });
            },
            size: 6,
            visible: ({values}) =>
              values.repository_id &&
              libraryType !== LibraryTypes.COMPONENT &&
              libraryType !== LibraryTypes.DATA_SERVICE,
          },
        ],
        {
          label: 'Language',
          type: 'autoComplete',
          field: 'language',
          options: ['JavaScript', 'TypeScript', 'Python', 'Java'],
          size: 6,
        },
        {
          label: 'Framework',
          type: 'autoComplete',
          field: 'framework',
          options: ({values}) => {
            if (values?.language) {
              return repositoryFrameworks?.[values?.language]?.[repoType];
            }
          },
          size: 6,
        },
        // {
        //   label: 'Dependency Libraries',
        //   type: 'multiAutoComplete',
        //   field: 'dependent_libraries',
        //   api: '/projectlibraries',
        //   suggestionField: 'name',
        //   valueField: 'name',
        //   secondarySuggestionField: 'type',
        //   filter: {project_id},
        // },
        {
          collapsedFields: [
            {
              label: 'Description',
              type: 'textArea',
              field: 'desc',
              inputProps: {
                minRows: 4,
                maxRows: 8,
              },
            },
            {
              label: 'Author',
              type: 'autoComplete',
              field: 'author',
              api: '/userSuggestions',
              params: {project_id},
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'email',
              colorField: 'color',
              avatar: true,
              size: 6,
              visible: props?.mode === 'edit',
            },
            {
              label: 'Contributors',
              type: 'multiAutoComplete',
              field: 'contributors',
              api: '/userSuggestions',
              params: {project_id},
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'email',
              colorField: 'color',
              avatar: true,
              visible: props?.mode === 'edit',
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddLibraryForm = props => {
  const {
    route: {
      params: {title, project_id, libraryType},
    },
  } = props;

  const {user} = useAppStateContext();

  const defaultValues = {
    type: libraryType,
    project_id,
    author: user,
    contributors: [user],
  };

  return (
    <LibraryForm
      header={`Add ${title}`}
      defaultValues={defaultValues}
      {...props}
    />
  );
};

export const ImportBoilerplateForm = props => {
  const {
    route: {params},
  } = props;

  const {project_id} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/ProjectLibraryIntegrations',
    eventSourceId: ['Library'],
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      header={'Import Libraries'}
      defaultValues={{
        integrated_project_id: project_id,
      }}
      layoutFields={[
        {
          label: 'Project Repository',
          type: 'autoComplete',
          field: 'repository_id',
          api: '/projectRepositories',
          filter: {project_id},
          suggestionField: 'repository',
          valueField: 'repository',
          required: true,
        },
        {
          label: 'Boilerplate and Configuration',
          fields: [
            {
              label: 'Boilerplate Name',
              type: 'autoComplete',
              field: 'library_id',
              api: `/ProjectLibraries`,
              filter: {
                type: 'boilerplate',
                latest_version_id: {$exists: true},
              },
              fields: {
                name: 1,
                type: 1,
                repository_id: {repository: 1, frameworks: 1, language: 1},
              },
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'desc',
              required: true,
              onChangeValue: (value, e, {setFieldValue}) => {
                console.log('🚀 ~ ImportBoilerplateForm ~ value:', value);
                if (value) {
                  setFieldValue('language', value.repository_id?.language);
                  setFieldValue('frameworks', value.repository_id?.frameworks);
                } else {
                  setFieldValue('language', void 0);
                  setFieldValue('frameworks', void 0);
                }
              },
              size: 6,
            },
            {
              label: 'Version',
              type: 'autoComplete',
              api: '/projectlibraryversions',
              field: 'library_version_id',
              filter: ({values}) => ({
                library_id: values.library_id,
              }),
              suggestionField: 'version',
              valueField: 'version',
              secondarySuggestionField: 'branch',
              visible: ({values}) => values.library_id,
              size: 6,
            },
            {
              label: 'App Name',
              field: 'app_name',
              type: 'text',
              size: 6,
              validate: (field, {values}) => {
                if (/[*#&!()]/.test(field)) {
                  return 'The name should not contain special characters.';
                }
              },
              visible: ({values}) =>
                values.library_id &&
                ['JavaScript', 'TypeScript'].includes(values.language),
              helperText: `App Name is your project name which would be reflect in your package.json file.`,
            },
            {
              label: 'Package Name',
              field: 'package_name',
              type: 'text',
              size: 6,
              validate: (field, {values}) => {
                if (values.frameworks.includes('react')) {
                  if (/[A-Z-*#&!()]/.test(field)) {
                    return 'The name should not contain capital letters and special characters.';
                  }
                }
                if (values.frameworks.includes('reactNative')) {
                  if (/[0-9-*#&!()]/.test(field)) {
                    return 'The name should not contain number and special characters.';
                  }
                }
              },
              required: true,
              visible: ({values}) => {
                return (
                  values.library_id &&
                  ['React Native', 'React & React Native'].includes(
                    values.frameworks,
                  )
                );
              },
              placeholder: 'com.example.myapp',
              helperText: `Enter a unique Bundle ID in reverse domain format (e.g., com.example.myapp) to identify your app on app stores.`,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

const BoilerplateForm = props => {
  const {
    navigation,
    route: {params},
  } = props;
  return (
    <Form
      submitAction="Save"
      layoutFields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          required: true,
        },
        {
          label: 'Repository',
          type: 'autoComplete',
          api: '/projectRepositories',
          field: 'repository_id',
          filter: {category: 'Boilerplate'},
          fields: {repository: 1, type: 1},
          onCreate: ({searchValue, onChange}) => {
            navigation.navigate('add-repository', {
              ...params,
              searchValue,
              category: 'Boilerplate',
              afterSubmit: ({data}) => onChange?.(data),
            });
          },
          onChangeValue: (value, e, {setFieldValue}) => {
            if (value) {
              setFieldValue('repository_type', value?.type);
            } else {
              setFieldValue('repository_type', void 0);
            }
          },
          suggestionField: 'repository',
          valueField: 'repository',
        },
        {
          collapsedFields: [
            {
              label: 'Description',
              type: 'textArea',
              field: 'desc',
              inputProps: {
                minRows: 4,
                maxRows: 8,
              },
            },
            {
              label: 'AddOn Library',
              nested: true,
              field: 'addon_libraries',
              render: props => (
                <NestedTable
                  {...props}
                  fields={[
                    {
                      label: 'Library',
                      field: 'library_id',
                      type: 'autoComplete',
                      api: `/projectLibraries`,
                      filter: {
                        // _id: {$nin: [...importedLibraryIds, library?._id]},
                        type: {$ne: 'boilerplate'},
                        latest_version_id: {$exists: true},
                      },
                      suggestionField: 'name',
                      valueField: 'name',
                      required: true,
                    },
                    {
                      label: 'Version',
                      field: 'library_version_id',
                      type: 'autoComplete',
                      api: `/projectLibraryVersions`,
                      suggestionField: 'version',
                      valueField: 'version',
                      filter: ({values}) => ({
                        library_id: values?.library_id,
                      }),
                      required: true,
                      visible: ({values}) => !!values?.library_id,
                    },
                    {
                      label: 'Copy code',
                      field: 'copy_code',
                      type: 'checkbox',
                    },
                  ]}
                  columns={[
                    {
                      header: 'Library',
                      field: 'library_id.name',
                      type: 'text',
                    },
                    {
                      header: 'Library Version Id',
                      field: 'library_version_id.version',
                      type: 'text',
                    },
                    {
                      header: 'Copy Code',
                      field: 'copy_code',
                      type: 'text',
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddBoilerplateForm = props => {
  const {
    route: {params},
  } = props;

  const {project_id} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/ProjectLibraries',
    eventSourceId: ['Library'],
    ...props,
  });

  const defaultValues = {
    type: 'boilerplate',
    project_id,
  };

  return (
    <BoilerplateForm
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      header={'Add Boilerplate'}
      {...props}
    />
  );
};

export const EditBoilerplateForm = props => {
  const {
    route: {params},
  } = props;

  const {projectlibrary_id} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/ProjectLibraries',
    eventSourceId: ['Library'],
    ...props,
  });

  return (
    <BoilerplateForm
      onSubmit={onSubmit}
      mode={'edit'}
      api={`/projectLibraries/${projectlibrary_id}`}
      fields={{
        repository_id: {repository: 1},
        name: 1,
        addon_libraries: {
          library_id: {name: 1},
          library_version_id: {version: 1},
          copy_code: 1,
        },
        desc: 1,
      }}
      header={'Edit Boilerplate'}
      {...props}
    />
  );
};

export const EditLibraryForm = props => {
  const {
    route: {
      params: {title, row},
    },
  } = props;
  return (
    <LibraryForm
      header={`Edit ${title}`}
      mode={'edit'}
      api={`/projectlibraries/${row._id}`}
      fields={{
        repository_id: {repository: 1},
        name: 1,
        folder_path: {path: 1},
        language: 1,
        framework: 1,
        desc: 1,
        author: {name: 1},
        dependent_libraries: {name: 1},
        contributors: {name: 1},
      }}
      {...props}
    />
  );
};

export const PublishLibraryForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/publishprojectlibrary',
    eventSourceId: ['Library'],
    ...props,
  });

  let initialValues = {
    library_id: row?._id,
    library_type: row.type,
    project_id: params?.project_id,
  };

  return (
    <Form
      header={`Publish ${row?.name}`}
      initialValues={initialValues}
      onSubmit={onSubmit}
      mode={'edit'}
      layoutFields={[
        {
          label: 'Version',
          type: 'text',
          field: 'version',
          required: true,
        },
        {
          label: 'Branch',
          type: 'text',
          field: 'branch',
        },
      ]}
    />
  );
};

export const ImportLibraryForm = ({navigation, route: {params}, ...props}) => {
  const {
    project_id,
    library,
    libraryType,
    importedLibraryIds = [],
    clone,
  } = params;

  const initialValues = {
    project_id,
  };
  let header = 'Import Library';
  let submitProps = {
    uri: `/projectlibraries/import`,
    eventSourceId: ['Library'],
  };
  if (clone) {
    submitProps = {
      uri: `/copyCodeAndData`,
    };
    header = 'Clone Library';
    initialValues.library = library;
    initialValues.type = libraryType;
  }
  const {onSubmit} = useFormSubmit({
    ...submitProps,
    ...props,
  });

  return (
    <Form
      initialValues={initialValues}
      header={header}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Library',
          field: 'library_id',
          type: 'autoComplete',
          api: `/projectlibraries`,
          filter: {
            _id: {$nin: [...importedLibraryIds, library?._id]},
            type: libraryType,
            latest_version_id: {$exists: true},
          },
          suggestionField: 'name',
          valueField: 'name',
          fields: {
            name: 1,
            latest_version_id: {
              version: 1,
            },
          },
          required: true,
          onChangeValue: (value, e, {setFieldValue}) => {
            setFieldValue(
              'library_version_id',
              value?.latest_version_id ? value.latest_version_id : void 0,
            );
          },
        },
        {
          label: 'Version',
          field: 'library_version_id',
          type: 'autoComplete',
          api: `/projectlibraryversions`,
          suggestionField: 'version',
          valueField: 'version',
          filter: ({values}) => {
            return {
              library_id: values?.library_id,
            };
          },
          required: true,
          visible: ({values}) => !!values?.library_id,
        },
      ]}
    />
  );
};

export const ChangeIntegratedProjectLibraryVersion = ({
  route: {params},
  ...props
}) => {
  const {project_id, libraryVersion, row} = params;

  const {onSubmit} = useFormSubmit({
    uri: `/updateintegratedprojectlibraryversion`,
    eventSourceId: ['Library'],
    ...props,
  });

  const initialValues = {
    library_version_id: libraryVersion,
    project_id,
    library_id: row?._id,
  };

  return (
    <Form
      initialValues={initialValues}
      header={'Import Library'}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Version',
          field: 'library_version_id',
          type: 'autoComplete',
          api: `/projectlibraryversions`,
          suggestionField: 'version',
          valueField: 'version',
          filter: {library_id: row?._id, _id: {$ne: libraryVersion._id}},
          required: true,
        },
      ]}
    />
  );
};
