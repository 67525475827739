import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const UpdateIssueForm = props => {
  const {
    route: {params = {}},
  } = props;

  const {project, issue} = params;

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'Issue',
    uri: '/projectIssues',
  });

  const priorityOptions = [
    {
      label: 'URGENT',
      value: 'URGENT',
      chip: 'ERROR',
    },
    {label: 'HIGH', value: 'HIGH', chip: 'ACCENT4'},
    {
      label: 'MEDIUM',
      value: 'MEDIUM',
      chip: 'ACCENT1',
    },
    {label: 'LOW', value: 'LOW', chip: 'ACCENT3'},
  ];

  return (
    <Form
      mode="edit"
      header="Issue Detail"
      api={`/projectIssues/${issue?._id}`}
      fields={{
        issue: 1,
        desc: 1,
        file: 1,
        type: 1,
        priority: 1,
        module_id: {module: 1},
      }}
      isDualMode
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Issue',
          type: 'textArea',
          field: 'issue',
          required: true,
          inputProps: {
            minRows: 2,
            maxRows: 6,
          },
        },
        {
          collapsedFields: [
            {
              label: 'Priority',
              type: 'autoComplete',
              field: 'priority',
              options: priorityOptions,
              options: priorityOptions,
              colorField: 'chip',
              valueField: 'label',
              keyField: 'value',
              suggestionField: 'label',
              showChip: true,
              size: 6,
            },
            {
              label: 'Type',
              type: 'autoComplete',
              field: 'type',
              options: ['Bug', 'Enhancement'],
              size: 6,
            },
            {
              label: 'Module',
              type: 'autoComplete',
              api: '/projectmodules',
              filter: {project_id: project?._id},
              placeholder: 'Select',
              suggestionField: 'module',
              valueField: 'module',
              field: 'module_id',
              size: 6,
            },
            {
              field: 'file',
              label: 'Attachments',
              type: 'file',
              multiple: true,
            },
            {
              label: 'Description',
              type: 'richText',
              field: 'desc',
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
