import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {CommonIssueTable} from './IssuesTable';
import {useFilter} from '@unthinkable/react-filters';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useGroupBy} from '../../../controllers/useGroupBy';
import {GroupBy} from '../../../components/table/GroupBy';
import {Labels} from '../constants/Label';

export const IssueTabs = props => {
  const {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, filterValues, applyFilter} = useFilter(
    {},
  );

  const {module, project, projectlibrary_id} = params;

  const groupBy = useGroupBy({
    options: [
      {
        label: 'Module',
        field: 'module_id',
        groupRow: {
          title: 'module',
          data: '_children',
          defaultExpanded: true,
        },
      },
    ],
  });


  const countProps = {
    project_id: project?._id,
    module_id: module?._id,
    library_id: projectlibrary_id || null,
    ...filterValues?.filter,
  };

  const tabs = {
    pending: {
      label: 'Pending',
      view: (
        <CommonIssueTable
          groupBy={groupBy}
          searchValue={searchValue}
          isPending
          filter={{
            project_id: project?._id,
            module_id: module?._id,
            library_id: projectlibrary_id || null,
            status: {$nin: ['active', 'resolved']},
            ...filterValues?.filter,
          }}
        />
      ),
      api: `/projectissues`,
      filter: {
        ...countProps,
        status: {$nin: ['active', 'resolved']},
      },
      eventSourceId: ['Issue', 'RemoveMappedFeature'],
      search: searchValue,
      searchFields: ['issue'],
    },
    active: {
      label: 'Active',
      view: (
        <CommonIssueTable
          groupBy={groupBy}
          searchValue={searchValue}
          filter={{
            project_id: project?._id,
            module_id: module?._id,
            library_id: projectlibrary_id || null,
            ...filterValues?.filter,
            status: 'active',
          }}
        />
      ),
      api: `/projectissues`,
      filter: {
        ...countProps,
        status: 'active',
      },
      search: searchValue,
      searchFields: ['issue'],
      eventSourceId: ['Issue', 'RemoveMappedFeature'],
    },
    resolved: {
      label: 'Resolved',
      view: (
        <CommonIssueTable
          groupBy={groupBy}
          searchValue={searchValue}
          filter={{
            project_id: project?._id,
            module_id: module?._id,
            library_id: projectlibrary_id || null,
            status: 'resolved',
            ...filterValues?.filter,
          }}
        />
      ),
      api: `/projectissues`,
      filter: {
        ...countProps,
        status: 'resolved',
      },
      eventSourceId: ['Issue', 'RemoveMappedFeature'],
      search: searchValue,
      searchFields: ['issue'],
    },
  };

  return (
    <TabView
      tabs={tabs}
      selectedTab={'active'}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        // <GroupBy {...groupBy} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: `${Labels.Feature}`,
              api: '/projectFeatures',
              suggestionField: 'feature',
              filter: {project_id: params?.project?._id, type: 'issue'},
              field: 'objective_ids',
              placeholder: `Select ${Labels.Feature}`,
            },
            {
              type: 'autoComplete',
              label: `Type`,
              options: ['Bug', 'Enhancement'],
              field: 'type',
              placeholder: `Select Type`,
            },
            !module?._id && {
              type: 'autoComplete',
              label: 'Module',
              api: '/projectmodules',
              placeholder: 'Select Module',
              suggestionField: 'module',
              valueField: 'module',
              field: 'module_id',
              filter: {project_id: params?.project?._id},
            },
          ]}
        />,
      ]}
    />
  );
};
