import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {
  GroupFilter,
  PeriodRangeMonthWiseBreakupFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {TeamOfficeTimeTrackingReport} from './EmployeeOfficeTrackingReport';

export const TeamOfficeTrackingReport = props => {
  const {
    route: {params},
    user: {employee},
  } = props;
  const {filterValues, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {params: filterParams, filter} = filterValues;
  const tabs = {
    Direct: {
      label: 'Direct',
      view: (
        <TeamOfficeTimeTrackingReport
          filter={{
            ...filterParams,
            ...filter,
            reporting_to: employee._id,
          }}
          tab="Direct"
          employee={employee}
          api="/employeeofficetimetraking"
        />
      ),
    },
    InDirect: {
      label: 'In Direct',
      view: (
        <TeamOfficeTimeTrackingReport
          filter={{
            ...filterParams,
            ...filter,
            reporting_to: employee._id,
          }}
          tab="Indirect"
          employee={employee}
          api="/inDirectEmployeeofficetimetraking"
        />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'multiAutoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              titleField: 'name',
              field: 'employee',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Exceptional Shift',
              options: [
                {label: 'True', value: 'true'},
                {label: 'False', value: {$in: [null, false]}},
              ],
              placeholder: 'Select',
              field: 'is_exceptional_shift',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};
